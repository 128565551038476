import Table from './components/table.jsx';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { svSE } from '@mui/material/locale';

function App() {
  const theme = useTheme();

  return (
    <>
      <div>
        <ThemeProvider theme={createTheme(theme, svSE)}>
          <Table />        
        </ThemeProvider>
      </div>
    </>
  )
}

export default App
