import React, { useState, useMemo } from "react";
import carData from "../../data/cars.json";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import Tooltip from "@mui/material/Tooltip";

// Import SE
import { MRT_Localization_SV } from "material-react-table/locales/sv";

export default function table() {
  const columns = useMemo(
    () => [
      {
        accessorKey: "Brand",
        header: "Märke",
        size: 50,
      },
      {
        accessorKey: "Model",
        header: "Modell",
        size: 10,
      },
      {
        accessorKey: "Edition",
        header: "Edition",
        size: 30,
        maxSize: 40,
      },
      {
        accessorKey: "Battery_size",
        header: "Batteri",
        Header: () => (
          <Tooltip title="Nominell storlek på batteriet">
            <span>Batteri</span>
          </Tooltip>
        ),
        size: 50,
        filterVariant: "range",
        Cell: ({ cell }) => `${cell.getValue()} kWh`,
      },
      {
        accessorKey: "Range",
        header: "Räckvidd",
        size: 50,
        filterVariant: "range",
        Cell: ({ cell }) => `${cell.getValue()} km`,
      },
      {
        accessorKey: "Consumption",
        header: "Förbrukning",
        Header: () => (
          <Tooltip title="kWh per 100 km">
            <span>Förbrukning</span>
          </Tooltip>
        ),
        size: 50,
        filterVariant: "range",
        Cell: ({ cell }) =>
          `${cell.getValue()?.toFixed(1).replace(".", ",")} kWh`,
      },
      {
        accessorKey: "Fast_Charging_Max",
        header: "Snabbladdning",
        Header: () => (
          <Tooltip title="Högsta möjliga effekt vid snabbladdning">
            <span>Snabbladdning</span>
          </Tooltip>
        ),
        size: 50,
        filterVariant: "range",
        Cell: ({ cell }) => `${cell.getValue()} kW`,
      },
      {
        accessorKey: "Hoursepower",
        header: "Hästkrafter",
        size: 50,
        filterVariant: "range",
        Cell: ({ cell }) => `${cell.getValue()} hk`,
      },
      {
        accessorKey: "Year_Model",
        header: "Årsmodell",
        size: 50,
      },
    ],
    [],
  );

  const [data, setData] = useState(() => [...carData]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: "compact",
      sorting: [
        {
          id: "Brand",
          desc: false,
        },
      ],
    },
    enableFullScreenToggle: false,
    enablePagination: false,
    localization: MRT_Localization_SV,
    enableHiding: false,
    enableStickyHeader: true,
    muiTableBodyProps: {
      sx: {
        "& tr:nth-of-type(odd) > td": {
          backgroundColor: "#FAFAFA",
        },
        "& tr > td:nth-of-type(1), & tr > td:nth-of-type(2)": {
          fontWeight: 700,
        },
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
  });

  return <MaterialReactTable table={table} />;
}
